<template>
  <h2 class="my-4 text-center">
    This page is under construction!
  </h2>
</template>

<script>
export default {
  name: 'UnderConstruction',
}
</script>

<style scoped></style>
